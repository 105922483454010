<template>
  <div class="w3-container w3-cell contents-wrap">
    <base-loading />

    <!-- :isUser="$attrs.isUser"
        :isAgent="$attrs.isAgent"
        :isClientManager="$attrs.isClientManager" -->
    <comp-app-bar
        :userInfo="$attrs.userInfo"
        :role="$attrs.role"
        :companyOrAgentType="$attrs.companyOrAgentType" />

    <router-view
      :role="$attrs.role"
      :userInfo="$attrs.userInfo"/>
  </div>
</template>

<script>
export default {
	name: 'CompCoreView',

	components: {
		CompAppBar: () => import('./AppBar'),
	},
  data: () => ({

  }),
}
</script>